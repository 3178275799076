body {
  margin: 0;
  overscroll-behavior: none;
  font-family: 'Comfortaa', 'DM Sans', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Comfortaa', 'DM Sans', 'Roboto', sans-serif;
}

.menu-item-container {
  padding: 16px;
  display: flex;
  flex-direction: column;

  .menu-item-buttons {
    display: flex;
    justify-content: flex-start;
    width: 200px;
    text-transform: capitalize;
  }
}

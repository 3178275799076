.card-item-container {
  margin: 0 16px 16px 16px;
  width: 248px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-header-text {
    max-width: 184px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.delete-property-popover {
  margin: 16px;
}

.footer-container {
  background-color: #eceff1;
  padding: 16px;
}

@media only screen and (max-width: 300px) {
  .footer-logo {
    img {
      height: 3vh;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .footer-logo {
    img {
      height: 3vh;
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .footer-logo {
    img {
      height: 4vh;
    }
  }
}

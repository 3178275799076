.column-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 5px #b3d9ff;
  width: 280px;
  background-color: #f5f5f5;
  margin-right: 16px;

  .column-header {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0 16px;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    padding-top: 1px;
    margin-top: 8px;
  }
}

.edit-property-modal-container {
  .board-title {
    display: flex;
    justify-content: center;
  }

  .edit-property-modal {
    display: flex;

    .left-side {
      .left-side-short-text {
        display: flex;
        margin-top: 8px;
      }
    }

    .left-side {
      .checkbox-container {
        margin-top: 8px;

        .checkbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      .rent-price-container {
        display: flex;

        .rent-price {
          margin-right: 8px;
        }
      }

      .size-container {
        display: flex;

        .size {
          margin-right: 8px;
        }
      }

      .bed-bath-container {
        display: flex;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
    }
  }

  .alert-container {
    margin: 8px 0;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    .cancel-save-property-button {
      margin-right: 16px;
      border-color: #7e57c2;
      color: #7e57c2;
      &:hover {
        border-color: #7e57c2;
      }
    }

    .save-property-button {
      background-color: #7e57c2;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
      &:hover {
        background-color: #7e57c2;
        opacity: 0.8;
      }
    }
  }
}

.sidebar-container {
  display: flex;
  height: 98vh;
  padding: 1vh;
  border-right: 1px solid #e0e3e7;
  background-color: #f5f5f5;

  .sidebar-left {
    display: flex;

    .collapse-wrapper {
      height: 98vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sidebar-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sidebar-logo {
          height: 32px;
        }

        .board-title-button {
          display: 'flex';
          justify-content: flex-start;
          text-align: left;
          width: 130px;
          text-transform: capitalize;

          .board-title-text {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      .sidebar-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sidebar-button {
          justify-content: flex-start;
          width: 130px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.sidebar-link {
  color: inherit;
  text-decoration: none;
}

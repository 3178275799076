.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

@media only screen and (max-width: 300px) {
  html:root {
    --swiper-navigation-size: 22px;
  }
}

@media only screen and (min-width: 300px) {
  html:root {
    --swiper-navigation-size: 36px;
  }
}

@media only screen and (min-width: 600px) {
  html:root {
    --swiper-navigation-size: 44px;
  }
}

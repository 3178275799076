.board-container {
  display: flex;

  .main-board {
    margin-left: 16px;

    .board-header {
      display: flex;

      .board-title {
        display: flex;
        align-items: center;
        padding: 1vh 1vh 1vh 0;
        height: 4vh;
        margin-right: 8px;
      }

      .board-alert {
        display: flex;
        align-items: center;
      }
    }

    .board-column {
      display: flex;
      height: 92vh;
      padding-bottom: 2vh;
    }
  }
}

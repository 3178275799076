.modal-container {
  display: flex;
  flex-direction: column;

  .textfield-container {
    display: flex;
    flex-direction: column;

    .rent-price-container {
      display: flex;
    }
  }

  .button-container {
    .cancel-add-property-button {
      margin-right: 16px;
      border-color: #7e57c2;
      color: #7e57c2;
      &:hover {
        border-color: #7e57c2;
      }
    }

    .add-property-button {
      background-color: #7e57c2;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
      &:hover {
        background-color: #7e57c2;
        opacity: 0.8;
      }
    }
  }
}

.terms-container {
  margin: 32px;

  .main-title {
    text-align: center;
    margin: 16px auto;
    font-weight: bold;
  }

  .bold-text {
    font-weight: bold;
  }

  .sub-title {
    font-weight: bold;
  }

  .numbered-list {
    margin-left: 8px;

    .numbered-sub-list {
      margin-left: 16px;
    }
  }
}

.intro-container {
  width: 100%;
  height: 100vh;
  background-color: #eceff1;
  display: flex;
  justify-content: center;

  .intro-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .intro-button {
    background-color: #a775d9;
    &:hover {
      background-color: #a775d9;
      opacity: 0.8;
    }
  }

  .intro-image {
    width: 80%;
    height: auto;
  }
}

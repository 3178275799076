.services-container {
  .services-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .services-button {
      background-color: #a775d9;
      &:hover {
        background-color: #a775d9;
        opacity: 0.8;
      }
    }
  }
}

.appbar-container {
  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  .toolbar-right {
    display: flex;
  }
}

@media only screen and (max-width: 300px) {
  .toolbar-left {
    img {
      height: 4vh;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .toolbar-left {
    img {
      height: 5vh;
    }
  }
}

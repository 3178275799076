.property-banner-container {
  height: 2vh;
  margin: 1vh;
  display: flex;
  align-items: center;

  .MuiAlert-action {
    padding-top: 0;
  }
}
